.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-top: calc(10px + 2vmin);
}

#titleImage {
  transform: translateY(10px);
}

span {
  font-size: 30px;
  font-weight: bold;
}

#blessing {
  font-size: 50px;
  font-weight: bold;
  color: red;
}